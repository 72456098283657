import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <h2>
          <code>Rhobericci, LLC</code>
        </h2>
        <p>
          <code>Strategic Solutions Via Busy Young Executives</code>
        </p> */}
        <h2>
          <code>METATEX</code>
        </h2>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          <code>Coming soon...</code>
        </p>
      </header>
    </div>
  );
}

export default App;
